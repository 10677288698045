

































































































































































































import { Fzf } from 'fzf';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { namespace } from 'vuex-class';
import { Environment, Project } from '../api';
import * as api from '../api';
import { ability } from '../auth/abilities';
import EnvironmentIcon from '../components/EnvironmentIcon.vue';
import ExtensionSearch from '../components/ExtensionSearch.vue';
import FileUpload from '../components/FileUpload.vue';
import ListEditor from '../components/ListEditor.vue';
import SaveButton from '../components/SaveButton.vue';
import { IListFileParams } from '../store/file.module';
import { IToast } from '../store/toast.module';
import { downloadUrlToString } from '../utils';

const environment = namespace('environment');
const toast = namespace('toast');
const file = namespace('file');
const project = namespace('project');

type KeyValue = { key: string; value: string };

@Component({
  components: { ListEditor, FileUpload, ExtensionSearch, EnvironmentIcon, SaveButton },
})
export default class EnvironmentEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  @environment.Getter
  item!: Environment;

  @file.Getter('items')
  files!: File[];

  @file.Action('loadItems')
  private loadFiles!: (params?: IListFileParams) => Promise<void>;

  @environment.Action
  private loadItem!: (id: string) => Promise<void>;

  @environment.Action
  private loadItemForDuplication!: (id: string) => Promise<void>;

  @environment.Action
  private createNewItem!: () => Promise<void>;

  @environment.Action
  private saveItem!: () => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @project.Getter
  currentItem!: Project;

  @Watch('currentItem._id')
  async onCurrentItemChanged() {
    await this.$router.push({ name: 'environments', params: { projectId: this.currentItem._id } });
  }

  isUploading = false;

  async created(): Promise<void> {
    this.state.loaded = false;
    const id = this.$route.params.id;
    const copy = this.$route.query.copy;
    if (typeof copy === 'string') {
      await this.loadItemForDuplication(copy);
    } else if (id) {
      await this.loadItem(id);
    } else {
      await this.createNewItem();
    }
    await this.loadFiles({ $type: 'upload' });
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    await this.save();
    await this.$router.push({ name: 'environments', params: { projectId: this.currentItem._id } });
  }

  async save(): Promise<void> {
    await this.saveItem();
    this.saveButton.setPristine();
    this.setToast({ message: `Environment “${this.item.description}” was saved.` });
  }

  uploadStarted(): void {
    this.isUploading = true;
  }

  uploadFinished(): void {
    this.isUploading = false;
  }

  get cannotEdit(): boolean {
    return !!this.item._id && !ability.can('update', this.item);
  }

  get cannotInvalidate(): boolean {
    return !this.item._id || !ability.can('invalidate', this.item);
  }

  readonly autoCompleteStyle = {
    defaultInput: 'form-control',
    suggestions: 'list-group',
    suggestItem: 'list-group-item list-group-item-action',
  };

  async suggestPrefKeys(value?: string): Promise<KeyValue[]> {
    if (this.item.type !== 'knime') return [];
    const file = this.item.epfFile;
    if (!value || !file) return [];
    // XXX could cache this - but must invalidate cache when changing file - worth it?!
    const fileContent = await downloadUrlToString(`/api/${file.download}`);
    const epfRows: { value: string; key: string }[] = this.item.epfRows;
    const lines = fileContent
      .split('\n')
      .filter((line) => line.startsWith('/instance/'))
      .map((line) => line.replace(/^\/instance\//, ''))
      .map((line) => line.split(/(?<!\\)=/)) // = can be escaped as \=
      .map((split) => ({ key: split[0], value: split[1] }))
      .filter((item) => !epfRows.some((row) => row.key === item.key));
    const fzf = new Fzf(lines, { selector: (item) => item.key, limit: 10 });
    const matches = fzf.find(value);
    return matches.map((item) => item.item);
  }

  selectPrefKey(event: KeyValue, row: KeyValue): void {
    row.value = event.value;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }

  async clearCache(environment: Environment): Promise<void> {
    await api.invalidateEnvironment(environment._id);
    this.setToast({ message: `Environment “${environment.description}” cache was cleared.` });
  }
}
