


























































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faAnglesDown, faBan, faExclamationTriangle, faSync } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Project, Run } from '../api';
import DateTime from '../components/DateTime.vue';
import RunStatus from '../components/RunStatus.vue';
import { IToast } from '../store/toast.module';
import { downloadUrl } from '../utils';

library.add(faExclamationTriangle, faBan, faSync, faAnglesDown);

const run = namespace('run');
const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { RunStatus, DateTime },
})
export default class RunView extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  private pollInterval: number | undefined;

  @run.Getter
  item!: Run;

  @run.Getter
  private log!: string[];

  @run.Action
  private loadItem!: (id: string) => Promise<void>;

  @run.Action
  private updateItem!: (id: string) => Promise<void>;

  @run.Action
  private loadLog!: (id: string) => Promise<void>;

  @run.Action
  private updateLog!: (id: string) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @project.Getter
  currentItem!: Project;

  followLog = false;

  @Watch('currentItem._id')
  async onCurrentItemChanged() {
    await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
  }

  async created(): Promise<void> {
    this.state.loaded = false;
    await this.loadItem(this.$route.params.id);
    this.state.loaded = true;
    this.followLog = this.item.active;
    await this.loadLog(this.$route.params.id);
    if (this.followLog) {
      this.scrollToEnd();
    }
    if (this.item.active) {
      this.pollInterval = setInterval(async () => {
        await this.updateItem(this.$route.params.id);
        await this.updateLog(this.$route.params.id);
        if (this.followLog) {
          this.scrollToEnd();
        }
        if (!this.item.active) {
          clearInterval(this.pollInterval);
          this.followLog = false;
        }
      }, 5000);
    }
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  get lines(): string[] {
    const lines: string[] = this.log ? [...this.log] : [];
    if (this.item.active) {
      lines.push('...');
    }
    if (lines.length === 0) {
      lines.push('No console output available.');
    }
    return lines;
  }

  async abort(run: Run): Promise<void> {
    const reallyAbort = await this.$bvModal.msgBoxConfirm(`Really abort run ${run._id}?`, {
      okVariant: 'danger',
      okTitle: 'Abort',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyAbort) {
      await api.cancelRun(run._id);
      await this.updateItem(run._id);
      this.setToast({ message: `Run ${run._id} was canceled.` });
    }
  }

  async remove(run: Run): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete ${run._id}?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteRun(run._id);
      await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
      this.setToast({ message: `Run ${run._id} was deleted.` });
    }
  }

  async retry(run: Run): Promise<void> {
    await api.retryRun(run._id);
    await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
    this.setToast({ message: `Retried running ${run._id}.` });
  }

  downloadArtefact(): void {
    if (this.item.artifact) {
      downloadUrl(`/api/${this.item.artifact.download}`);
    }
  }

  getLogRowClass(line: string): string | undefined {
    for (const keyword of ['error', 'warn', 'info', 'debug']) {
      if (line.toLowerCase().includes(keyword)) {
        return keyword;
      }
    }
  }

  follow(): void {
    // if the run is active, the button acts as toggle,
    // otherwise it will just trigger the scrolling
    if (this.item.active) {
      this.followLog = !this.followLog;
    }
    if (!this.item.active || this.followLog) {
      this.scrollToEnd();
    }
  }

  private scrollToEnd(): void {
    this.$nextTick(() => {
      const lastRow = document.querySelector('.console div:last-child');
      lastRow?.scrollIntoView({ behavior: 'smooth' });
    });
  }
}
