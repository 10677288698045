















































































import SaveButton from '@/components/SaveButton.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter, namespace } from 'vuex-class';
import { Project, User, UserList } from '../api';
import { ability } from '../auth/abilities';
import ListEditor from '../components/ListEditor.vue';
import { IToast } from '../store/toast.module';

const project = namespace('project');
const toast = namespace('toast');
const user = namespace('user');

@Component({
  components: { ListEditor, SaveButton },
})
export default class ProjectEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  isRowEditable(row: any): boolean {
    // XXX If we want to allow editing oneself, we need to handle some edge cases:
    // - Abilities need to be checked based on the initial state of project
    //   e.g., when I remove myself, I still need to able to save the form.
    // - Changing the role should not be allowed when creating a new project,
    //   otherwise I can create projects for other users.
    const isMe = this.me && this.me._id === row.user;
    const owners = this.item.members.filter((m) => m.user && m.role === 'owner');
    const isSoleOwner = owners.length === 1 && row.user == owners[0].user;
    return !isMe && !isSoleOwner;
  }

  @Getter
  me!: User | null;

  @project.Getter
  item!: Project;

  @user.Getter('users')
  userList!: UserList;

  @project.Action
  private loadItem!: (id: string) => Promise<void>;

  @project.Action
  private createNewItem!: () => Promise<void>;

  @project.Action
  private saveItem!: () => Promise<void>;

  @user.Action
  private loadUsers!: (params?: { q?: string }) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @project.Getter
  currentItem!: Project;

  @Watch('currentItem._id')
  async onCurrentItemChanged() {
    await this.$router.push({ name: 'environments', params: { projectId: this.currentItem._id } });
  }

  getUserSuggestions(selectedUser: string) {
    return this.userList.users
      .filter((u) => u._id === selectedUser || !this.item.members.some((m) => u._id === m.user))
      .sort((a, b) => a.email.localeCompare(b.email));
  }

  async created(): Promise<void> {
    if (!this.cannotList) {
      await this.loadUsers();
    }
    this.state.loaded = false;
    const id = this.$route.params.projectId;
    if (id) {
      await this.loadItem(id);
      this.state.loaded = true;
    } else {
      await this.createNewItem();
      this.state.loaded = true;
      this.$nextTick(() => {
        this.saveButton.setDirty();
      });
    }
  }

  async submit(): Promise<void> {
    await this.save();
    await this.$router.push({ name: 'projects' });
  }

  async save(): Promise<void> {
    await this.saveItem();
    this.saveButton.setPristine();
    this.setToast({ message: `Project “${this.item.description}” was saved.` });
  }

  get cannotEdit(): boolean {
    return !!this.item._id && !ability.can('update', this.item);
  }

  get cannotList(): boolean {
    return !ability.can('list', 'user');
  }

  getUserEmail(userId: string): string | undefined {
    return this.userList.users.find((u) => u._id === userId)?.email;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }
}
