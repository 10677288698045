import { impersonatedUserIdKey } from './store/impersonation.module';

function buildDownloadUrl(url: string): string {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token in Local Storage');
  }
  // assume given URL is relative,
  // so must consider the current location
  const urlObj = new URL(url, window.location.toString());
  urlObj.searchParams.append('token', token);
  const impersonatedUserId = sessionStorage.getItem(impersonatedUserIdKey);
  if (typeof impersonatedUserId === 'string') {
    urlObj.searchParams.append('nodepit-impersonated-userid', impersonatedUserId);
  }
  return urlObj.toString();
}

export function downloadUrl(url: string): void {
  window.location.assign(buildDownloadUrl(url));
}

export async function downloadUrlToString(url: string): Promise<string> {
  const downloadUrl = buildDownloadUrl(url);
  const fetchResult = await fetch(downloadUrl);
  if (!fetchResult.ok) {
    throw new Error(`Fetching ${downloadUrl} failed with status ${fetchResult.status}`);
  }
  return await fetchResult.text();
}

export type DateAndTime = { date: string; time: string; combined: string };

export function formatDateTime(dateString: string | undefined, inline = false): DateAndTime | undefined {
  if (typeof dateString === 'undefined') {
    return undefined;
  }

  function inlineCapitalization(str: string): string {
    return inline ? str.toLowerCase() : str;
  }

  function timezoneShiftedDate(d: Date): Date {
    return new Date(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().replace(/T.*/, ''));
  }

  function differenceInDays(d1: Date, d2: Date) {
    // nb: must adjust by timezone offset!
    // https://stackoverflow.com/a/29774197
    const d1Day = timezoneShiftedDate(d1);
    const d2Day = timezoneShiftedDate(d2);
    return (d1Day.getTime() - d2Day.getTime()) / (1000 * 60 * 60 * 24);
  }

  const dateInstance = new Date(dateString);
  const daysDifference = differenceInDays(dateInstance, new Date());
  let date;
  if (daysDifference === 0) {
    date = inlineCapitalization('Today');
  } else if (daysDifference === 1) {
    date = inlineCapitalization('Tomorrow');
  } else if (daysDifference === -1) {
    date = inlineCapitalization('Yesterday');
  } else {
    date = dateInstance.toISOString().replace(/T.*/, '');
  }

  const time = dateInstance.toLocaleTimeString('en', { timeStyle: 'short', hourCycle: 'h23' });

  return { date, time, combined: `${date}, ${time}` };
}
