import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Environment } from '../api';
import * as api from '../api';

export interface IEnvironmentState {
  items: Environment[] | null;
  item: Environment | null;
}

const state: IEnvironmentState = {
  items: null,
  item: null,
};

const getters: GetterTree<IEnvironmentState, IRootState> = {
  items: (state: IEnvironmentState) => state.items,
  item: (state: IEnvironmentState) => state.item,
};

// https://github.com/knime/knime-sdk-setup/blob/master/org.knime.sdk.setup/KNIME-AP.target
const vmargs = [
  '-server',
  '-Dsun.java2d.d3d=false',
  '-Dosgi.classloader.lock=classname',
  '-XX:+IgnoreUnrecognizedVMOptions',
  '-XX:+UnlockDiagnosticVMOptions',
  '-Dsun.net.client.defaultReadTimeout=0',
  '-XX:CompileCommand=exclude,javax/swing/text/GlyphView,getBreakSpot',
  '-Dknime.xml.disable_external_entities=true',
  '--add-opens=java.base/java.lang=ALL-UNNAMED',
  '--add-opens=java.base/java.lang.invoke=ALL-UNNAMED',
  '--add-opens=java.base/java.net=ALL-UNNAMED',
  '--add-opens=java.base/java.nio=ALL-UNNAMED',
  '--add-opens=java.base/java.nio.channels=ALL-UNNAMED',
  '--add-opens=java.base/java.util=ALL-UNNAMED',
  '--add-opens=java.base/java.util.regex=ALL-UNNAMED',
  '--add-opens=java.base/sun.nio.ch=ALL-UNNAMED',
  '--add-opens=java.base/sun.nio=ALL-UNNAMED',
  '--add-opens=java.desktop/javax.swing.plaf.basic=ALL-UNNAMED',
  '--add-opens=java.base/sun.net.www.protocol.http=ALL-UNNAMED',
  '--add-opens=java.base/sun.net.www.protocol.https=ALL-UNNAMED',
  '--add-opens=java.security.jgss/sun.security.krb5=ALL-UNNAMED',
  // below is needed to avoid a crash of ja JavaVM on Docker with Apple Silicon
  // https://github.com/docker/for-mac/issues/7286#issuecomment-2124475671
  // (we didn't encounter it with Runner yet, but had the issue when running
  // the NodePit crawler locally - so this is a pre-emptive measure)
  '-XX:+UseSerialGC',
].join('\n');

const actions: ActionTree<IEnvironmentState, IRootState> = {
  loadItems: async (context: ActionContext<IEnvironmentState, IRootState>) => {
    const { environments } = await api.listEnvironments({ project: context.rootState.project.currentItem?._id });
    context.commit('setItems', environments);
  },
  loadItem: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    const item = await api.getEnvironment(id);
    context.commit('setItem', item);
  },
  loadItemForDuplication: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    const item = await api.getEnvironment(id);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, description, ...rest } = item;
    const duplicatedItem = {
      description: `${description} (copy)`,
      ...rest,
    };
    context.commit('setItem', duplicatedItem);
  },
  createNewItem: (context: ActionContext<IEnvironmentState, IRootState>) => {
    const project = context.rootState.project.currentItem?._id;
    if (typeof project === 'undefined') {
      throw new Error('No project set');
    }
    const item: api.CreateKnimeEnvironment = {
      type: 'knime',
      description: '',
      knimeVersion: '5.3.2',
      vmargs,
      project,
    };
    context.commit('setItem', item);
  },
  saveItem: async (context: ActionContext<IEnvironmentState, IRootState>) => {
    if (!context.state.item) {
      throw new Error('No item in context');
    }
    let item;
    if (context.state.item._id) {
      item = await api.updateEnvironment(context.state.item._id, context.state.item);
    } else {
      item = await api.createEnvironment(context.state.item);
    }
    context.commit('setItem', item);
  },
  deleteItem: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    await api.deleteEnvironment(id);
    context.dispatch('loadItems');
  },
};

const mutations: MutationTree<IEnvironmentState> = {
  setItems(state: IEnvironmentState, items: Environment[] | null) {
    state.items = items;
  },
  setItem(state: IEnvironmentState, item: Environment | null) {
    state.item = item;
  },
};

const environment: Module<IEnvironmentState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default environment;
