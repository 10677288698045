






































































import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import { Executor } from '../api';
import ExecutorStatusIcon from '../components/ExecutorStatusIcon.vue';
import SaveButton from '../components/SaveButton.vue';
import { IToast } from '../store/toast.module';
import { formatDateTime } from '../utils';

const toast = namespace('toast');
const executor = namespace('executor');

@Component({
  components: { SaveButton, ExecutorStatusIcon },
})
export default class ExecutorEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  @executor.Getter
  executor!: Executor;

  @executor.Action
  private loadExecutor!: (id: string) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async created(): Promise<void> {
    this.state.loaded = false;
    await this.loadExecutor(this.$route.params.id);
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    /*await */ this.save();
    await this.$router.push({ name: 'executors' });
  }

  save(): void {
    this.saveButton.setPristine();
    this.setToast({ message: `Executor “${this.executor.description}” was saved.` });
  }

  get cannotEdit(): boolean {
    return true;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }

  get status(): string {
    return capitalize(this.executor.status);
  }

  get createdAt(): string {
    return formatDateTime(this.executor.createdAt)?.combined ?? this.executor.createdAt;
  }

  get updatedAt(): string {
    return formatDateTime(this.executor.updatedAt)?.combined ?? this.executor.updatedAt;
  }
}
