




































































































































import { ability } from '@/auth/abilities';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faClock, faClone, faPlay, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Project, QueryEnvironmentType, Schedule, ScheduleList } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import EnvironmentIcon from '../components/EnvironmentIcon.vue';
import RunStatus from '../components/RunStatus.vue';
import SearchInput from '../components/SearchInput.vue';
import { IToast } from '../store/toast.module';

library.add(faPlus, faClock, faBan, faPlay, faClone);

const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { RunStatus, EmptyListPlaceholder, DateTime, SearchInput, EnvironmentIcon },
})
export default class ScheduleListComponent extends Vue {
  private pollInterval: number | undefined;
  scheduleList: ScheduleList | null = null;
  filter: QueryEnvironmentType = 'all';
  private q = '';

  @project.Getter
  currentItem!: Project;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @Watch('currentItem._id')
  onCurrentItemChanged() {
    this.load();
  }

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  onFilter(filter: QueryEnvironmentType) {
    this.filter = filter;
    this.load();
  }

  onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  private async load(): Promise<void> {
    if (this.currentItem) {
      this.scheduleList = await api.listSchedules({ $type: this.filter, q: this.q, project: this.currentItem._id });
    }
  }

  async remove(item: Schedule): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete schedule “${item.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteSchedule(item._id);
      this.load();
      this.setToast({ message: `Schedule “${item.description}” was deleted.` });
    }
  }

  async run(item: Schedule): Promise<void> {
    await api.runSchedule(item._id);
    await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
    this.setToast({ message: `Started running “${item.description}”.` });
  }

  get cannotCreate(): boolean {
    return !ability.can('create', { kind: 'schedule', project: this.currentItem._id });
  }

  get cannotCreateRun(): boolean {
    return !ability.can('create', { kind: 'run', project: this.currentItem._id });
  }

  get filterOptions(): { key: QueryEnvironmentType; class: string; label: string; count: number }[] {
    const scheduleList = this.scheduleList;
    if (!scheduleList) {
      return [];
    }
    return (['all', 'knime', 'shell'] as const).map((property) => ({
      key: property,
      class: this.filter === property ? 'active' : '',
      label: property === 'knime' ? 'KNIME' : capitalize(property),
      count: scheduleList[`${property}Count` as keyof ScheduleList] as number,
    }));
  }
}
